// LikeButton.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { management_token, spaceId } from '../../config';
import { HiOutlineHeart } from "react-icons/hi2";
import "./LikeButton.scss";
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { fetchContents } from "../../redux/actions";

const LikeButton = ({ data }) => {
    const languageCode = "en-US";

    const { fields } = data;
    const { likes: likesData } = fields;
    const [likes, setLikes] = useState((likesData && likesData[languageCode]) || []);
    const user = localStorage.getItem("kps-user") && JSON.parse(localStorage.getItem("kps-user"));
    const username = user?.account?.username;
    const dispatch = useDispatch()

    let likeRef = useRef(null);

    const [isSelected, toggleSelection] = useState(false);

    useEffect(() => {
        if (likes && Array.isArray(likes) && likes?.includes(username)) {
            toggleSelection(true)
        } else {
            toggleSelection(false)
        }
    }, [likes, username])

    // Handle the like button click
    const handleLike = async (event) => {

        let selected = isSelected;

        toggleSelection(!selected)

        event.stopPropagation();

        if (!selected) {
            likeRef.current.children[0].style.fontSize = "26px"
            setTimeout(() => {
                likeRef.current.children[0].style.fontSize = "24px"
            }, 100);
        }

        try {
            // Get user info from local storage

            // Fetch the entry using Axios
            const entryResponse = await axios.get(
                `https://api.contentful.com/spaces/${spaceId}/environments/master/entries/${data.sys.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${management_token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            const entry = entryResponse.data;
            let likedUsers = entry.fields.likes?.[languageCode] || [];

            // Toggle like: add or remove the user from the likedUsers array
            const updatedLikes = likedUsers.includes(username)
                ? likedUsers.filter((user) => user !== username)
                : [...likedUsers, username];

            // Update the entry in Contentful
            entry.fields.likes = entry.fields.likes || {};
            entry.fields.likes[languageCode] = updatedLikes;

            // Use Axios to update the entry
            await axios.put(
                `https://api.contentful.com/spaces/${spaceId}/environments/master/entries/${data.sys.id}`,
                { fields: entry.fields },
                {
                    headers: {
                        Authorization: `Bearer ${management_token}`,
                        'Content-Type': 'application/vnd.contentful.management.v1+json',
                        'X-Contentful-Version': entry.sys.version, // Ensure the latest version is used
                    },
                }
            );

            // Optionally, update the UI optimistically
            setLikes(updatedLikes);
            dispatch(fetchContents());
        } catch (error) {
            console.error('Error updating likes:', error);
        }
    };


    return (
        <button onClick={handleLike} className='mt-[10px] flex' ref={likeRef}>
            <motion.div
                initial={{ scale: 1 }}
                animate={{ scale: isSelected ? 1.2 : 1 }}
                transition={{ type: 'spring', stiffness: 300, damping: 10 }}
                style={{ display: 'inline-block', cursor: 'pointer' }}
            >
                <HiOutlineHeart className='heart-icon' style={{ fill: isSelected && "#fe2b3e", stroke: isSelected && "none" }} />
            </motion.div>
            <span style={{ marginLeft: 4 }}>{likes?.length || 0} {likes?.length === 1 || likes?.length === 0 ? 'like' : 'likes'}</span>
        </button>
    );
};

export default LikeButton;
